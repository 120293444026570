<template>
  <div class="pageContainer">
    <el-card shadow="never">
      <div slot="header">
        <el-form :inline="true">
<!--          <el-form-item label="备案状态">-->
<!--            <el-select v-model="query.type" clearable>-->
<!--              <el-option v-for="item in CASE_STATUS_MAP" :label="item.label" :key="item.key" :value="item.key"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="queryData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-row type="flex" justify="start">
        <el-button type="primary" @click="handleClickAdd">新增园区</el-button>
      </el-row>
      <div class="tableWrap">
        <el-table :data="tableData"
                  border
                  v-loading="loadingList"
                  row-key="id">
          <el-table-column fixed prop="name" label="园区名称" min-width="100">
            <template v-slot="{ row }">
              <router-link :to="{name: 'parkDetail', params: { parkId: row.id }}"><el-button type="text">{{row.name}}</el-button></router-link>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="联系方式"></el-table-column>
          <el-table-column prop="loation" label="园区地址"></el-table-column>
          <el-table-column label="封面" width="140">
            <template v-slot="{ row }">
              <img :src="row.cover" class="displayImg" alt="">
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="120">
            <template v-slot="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          :current-page.sync="pageObj.currentPage"
          :total="pageObj.total"
          :page-sizes="pageObj.pageSizes"
          :page-size="pageObj.pageSize"
          @current-change = "handlerPageChange"
          @size-change = "handlerSizeChange"
          :layout="pageObj.layout"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import globalConfig from 'config/global'
import { execute, CASE_STATUS_MAP } from './option'
import { mapActions } from 'vuex'
export default {
  name: 'parkList',
  data() {
    return {
      CASE_STATUS_MAP,
      query: {
        type: undefined
      },
      tableData: [],
      loadingList: false,
      pageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      }
    }
  },
  created () {
   this.getListByPage(this.pageObj.currentPage)
  },
  methods: {
    ...mapActions(['getParkList', 'deletePark']),
    // 新增
    handleClickAdd() {
      execute('addOrEditPark', {
        optType: 'add',
        callback: () => this.getListByPage(1)
      })
    },
    queryData() {
      this.getListByPage(1)
    },
    getListByPage(pageNum) {
      this.loadingList = true
      this.getParkList({
        page: pageNum,
        size: this.pageObj.pageSize,
        ...this.query
      }).then(res => {
        console.log(res)
        const { recordList, total } = res
        this.tableData = recordList || []
        this.pageObj.total = total
      }).finally(() => {
        this.loadingList = false
      })
    },
    handlerPageChange(page) {
      this.getListByPage(page)
    },
    handlerSizeChange(size) {
      this.pageObj.pageSize = size
      this.getListByPage(1)
    },
    handleDelete(row) {
      this.$confirm('确认删除该供应商吗？').then(() => {
        this.deletePark(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.getListByPage(this.pageObj.currentPage)
        })
      })
    },
    handleEdit(row) {
      console.log(row)
      execute('addOrEditPark', {
        optType: 'edit',
        detail: { ...row },
        callback: () => this.getListByPage(this.pageObj.currentPage)
      })
    }
  }
}
</script>
<style>
.displayImg {
  display: block;
  max-width: 120px;
}
</style>
<style scoped>
.pageContainer {
  padding: 20px;
}
.tableWrap {
  margin: 20px 0;
}
</style>
